<template>
  <div>
    <div v-if="!showUploadModal">
      <div class="options justify-between my-6">
        <p class="font-semibold mr-6">
          {{ name }}
          <span class="text-lightGrey-2 font-normal">
            {{ fileName | truncate(30) }}</span
          >
        </p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          class="cursor-pointer"
          @click.stop="triggerUpload()"
          v-if="fileName === ''"
        >
          <path
            d="M13 8H8V13C8 13.55 7.55 14 7 14C6.45 14 6 13.55 6 13V8H1C0.45 8 0 7.55 0 7C0 6.45 0.45 6 1 6H6V1C6 0.45 6.45 0 7 0C7.55 0 8 0.45 8 1V6H13C13.55 6 14 6.45 14 7C14 7.55 13.55 8 13 8Z"
            fill="#918E9C"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          @click.stop="triggerUpload()"
          class="cursor-pointer"
          v-else
        >
          <path
            d="M0 15.4601V18.5001C0 18.7801 0.22 19.0001 0.5 19.0001H3.54C3.67 19.0001 3.8 18.9501 3.89 18.8501L14.81 7.94006L11.06 4.19006L0.15 15.1001C0.0500001 15.2001 0 15.3201 0 15.4601ZM17.71 5.04006C18.1 4.65006 18.1 4.02006 17.71 3.63006L15.37 1.29006C14.98 0.900059 14.35 0.900059 13.96 1.29006L12.13 3.12006L15.88 6.87006L17.71 5.04006Z"
            fill="#918E9C"
          />
        </svg>
      </div>
      <Hr />
    </div>

    <div v-if="showUploadModal">
      <h2 class="mt-6 md:text-2xl text-xl font-bold primary-text text-center">
        Upload {{ name }}
      </h2>
      <Upload
        class="mt-10"
        :fileType="['jpeg', 'jpg', 'png', 'pdf']"
        :text="fileName"
        @upload="handleUpload"
        @deleted="handleDelete"
        @fileUploaded="updateFileName"
      />
      <Button
        text="Proceed"
        :shadow="true"
        width="w-full"
        p="px-3 py-4"
        class="mt-10 place-self-end"
        :disabled="disableButton"
        @click="proceed()"
      />

      <SecondaryButton
        text="Cancel"
        width="w-full"
        p="px-3 py-4"
        background="bg-transparent"
        class="mt-2 place-self-end font-semibold"
        color="text-dark"
        fontSize="text-base"
        @click="cancel"
      />
    </div>
  </div>
</template>

<script>
  import { Upload } from "@/UI/Input";
  import { Modal } from "@/UI/Modals";
  import Hr from "@/UI/Hr";
  import { Button, SecondaryButton } from "@/UI/Button";
  export default {
    components: {
      Hr,
      Upload,
      Modal,
      Button,
      SecondaryButton,
    },
    props: {
      name: {
        type: String,
        default: "",
        required: false,
      },
      type: {
        type: String,
        default: "",
        required: true,
      },
    },
    data() {
      return {
        showUploadModal: false,
        file: "",
        fileName: "",
        updateName: "",
        showComponent: true,
      };
    },
    computed: {
      states() {
        return this.location.map((location) => location.state);
      },
      rules() {
        return {
          name: this.file !== "",
        };
      },
      disableButton() {
        return Object.values(this.rules).includes(false);
      },
    },
    methods: {
      handleUpload(e) {
        this.file = e.id;
      },
      triggerUpload() {
        this.showUploadModal = true;
        this.showComponent = true;
        this.$emit("hideUpload", this.type);
      },
      handleDelete() {
        this.file = "";
        this.fileName = "";
        this.$emit("deleted", this.type);
      },
      updateFileName(value) {
        this.updateName = value;
      },
      proceed() {
        this.fileName = this.updateName;
        this.showUploadModal = false;
        if (this.file !== "") {
          this.$emit("uploaded", this.file, this.type);
        }
      },
      cancel() {
        this.$emit("cancel");
        this.showUploadModal = false;
        this.showComponent = false;
        this.fileName = "";
      },
    },
  };
</script>

<style>
  .options {
    display: grid;
    grid-template-columns: 94% 1fr;
    grid-gap: 10px;
    align-content: center;
  }
</style>
