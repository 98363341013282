var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmploymentType,
              expression: "showEmploymentType",
            },
          ],
          staticClass: "container-body__surround scrollbar",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "container-body__content overflow-hidden lg:w-9/12 mx-auto grid",
            },
            [
              _c("transition", { attrs: { name: "slideX", mode: "out-in" } }, [
                _c("div", { staticClass: "flex flex-col justify-center" }, [
                  _vm.showStep === "form"
                    ? _c(
                        "div",
                        { staticClass: "w-full" },
                        [
                          _c("BackNav", {
                            on: {
                              click: function ($event) {
                                _vm.showEmploymentType = false
                              },
                            },
                          }),
                          _c(
                            "h5",
                            {
                              staticClass:
                                "md:text-2xl text-xl text-center font-bold primary-text leading-10",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.noEmployment ? "Update" : "Provide"
                                  ) +
                                  " your work information "
                              ),
                            ]
                          ),
                          _vm.employmentData.verificationStatus
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "text-center font-semibold refunded",
                                },
                                [
                                  _vm._v(
                                    " Verification " +
                                      _vm._s(
                                        _vm.employmentData.verificationStatus.toLowerCase()
                                      ) +
                                      "! "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.employmentData.verificationStatus === "EXPIRED"
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "text-center font-semibold refunded",
                                },
                                [
                                  _vm._v(
                                    " Please re-verify your employment details "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c("Input", {
                            staticClass: "mt-6",
                            attrs: {
                              placeholder: "Where do you work",
                              width: "w-full",
                              validation: _vm.rules.name,
                              errorText: "Please enter where you work",
                              disabled: _vm.disableInput,
                            },
                            model: {
                              value: _vm.employmentData.employerName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.employmentData,
                                  "employerName",
                                  $$v
                                )
                              },
                              expression: "employmentData.employerName",
                            },
                          }),
                          _c("Input", {
                            staticClass: "mt-8",
                            attrs: {
                              id: "address",
                              placeholder:
                                "What is the address of your workplace?",
                              width: "w-full",
                              disabled: _vm.disableInput,
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.validateAddress()
                              },
                              focus: function ($event) {
                                return _vm.handleError()
                              },
                            },
                            model: {
                              value: _vm.employmentData.officeAddress,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.employmentData,
                                  "officeAddress",
                                  $$v
                                )
                              },
                              expression: "employmentData.officeAddress",
                            },
                          }),
                          _c("transition", { attrs: { name: "fade-out" } }, [
                            _vm.errorOnAddress
                              ? _c(
                                  "p",
                                  { staticClass: "text-brandRed text-xs" },
                                  [
                                    _vm._v(
                                      " Please select a valid address from the dropdown "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c("Input", {
                            staticClass: "mt-8",
                            attrs: {
                              placeholder:
                                "Add more details to this address (if any)",
                              width: "w-full",
                              disabled: _vm.disableInput,
                            },
                            model: {
                              value:
                                _vm.employmentData.additionalAddressDetails,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.employmentData,
                                  "additionalAddressDetails",
                                  $$v
                                )
                              },
                              expression:
                                "employmentData.additionalAddressDetails",
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "mt-8 grid grid-cols-2 gap-4" },
                            [
                              _c("Select", {
                                attrs: {
                                  placeholder: "State",
                                  label: "name",
                                  options: _vm.states,
                                  reduce: function (category) {
                                    return category.name
                                  },
                                  disabled: _vm.disableInput,
                                },
                                model: {
                                  value: _vm.employmentData.state,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.employmentData, "state", $$v)
                                  },
                                  expression: "employmentData.state",
                                },
                              }),
                              _c("Select", {
                                attrs: {
                                  placeholder: "LGA",
                                  options: _vm.lga,
                                  reduce: function (category) {
                                    return category.name
                                  },
                                  label: "name",
                                  disabled: _vm.disableInput,
                                },
                                model: {
                                  value: _vm.employmentData.lga,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.employmentData, "lga", $$v)
                                  },
                                  expression: "employmentData.lga",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.disableInput
                            ? _c(
                                "div",
                                { staticClass: "mt-8" },
                                [
                                  _c("Button", {
                                    staticClass: "mt-8 w-full mb-2",
                                    attrs: { text: "Edit Information" },
                                    on: {
                                      click: function ($event) {
                                        _vm.disableInput = false
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _c("Button", {
                                    staticClass: "mt-8 w-full mb-2",
                                    attrs: {
                                      text: "Verify Employment",
                                      disabled: _vm.disableButton,
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.showStep = "verification"
                                      },
                                    },
                                  }),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-font-semibold text-brandPurple text-center my-4 text-sm cursor-pointer",
                                      on: { click: _vm.handleClose },
                                    },
                                    [_vm._v(" Skip, I will do it later ")]
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "overflow-hidden" },
                    [
                      _c(
                        "transition",
                        { attrs: { name: "slideX", mode: "out-in" } },
                        [
                          _vm.showStep === "verification"
                            ? _c("VerificationOptions", {
                                attrs: {
                                  type: _vm.type,
                                  company: _vm.employmentData.employerName,
                                },
                                on: {
                                  verificationType: _vm.chooseVerification,
                                  select: _vm.selected,
                                  back: function ($event) {
                                    _vm.showStep = "form"
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.showStep === "email"
                            ? _c("EmailVerification", {
                                attrs: {
                                  employmentData: _vm.employmentData,
                                  requestMethod: _vm.requestMethod,
                                },
                                on: {
                                  next: function ($event) {
                                    return _vm.$emit("email")
                                  },
                                  back: function ($event) {
                                    _vm.showStep = "verification"
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.showStep === "upload"
                            ? _c("UploadVerification", {
                                attrs: {
                                  requestMethod: _vm.requestMethod,
                                  employmentData: _vm.employmentData,
                                },
                                on: {
                                  next: function ($event) {
                                    return _vm.$emit("documents")
                                  },
                                  back: function ($event) {
                                    _vm.showStep = "verification"
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ]
      ),
      !_vm.showEmploymentType
        ? _c("EmploymentType", {
            on: {
              next: function ($event) {
                _vm.showEmploymentType = true
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }