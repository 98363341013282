<template>
  <div class="scrollbar">
    <BackNav @click="$emit('back')" />

    <h5 class="md:text-2xl text-xl text-center font-bold primary-text">
      Let’s verify you work for {{ company }}
    </h5>
    <p class="text-center text-grey">Choose your verification method</p>
    <div class="grid md:grid-cols-2 gap-4 mt-4">
      <div
        class="cursor-pointer card-options border rounded-lg"
        @click="$emit('verificationType', 'EMAIL')"
        :class="[type === 'EMAIL' ? 'border-brandPurple' : 'options-border']"
      >
        <!-- <div class="items-center"> -->
        <div class="px-4">
          <p class="text-lg font-semibold text-dark">Work email</p>
          <p class="text-xs md:text-sm text text-grey">
            You will be prompted to confirm your work email with an OTP
          </p>
        </div>
        <!-- </div> -->
      </div>
      <div
        class="cursor-pointer card-options border rounded-lg"
        @click="$emit('verificationType', 'UPLOAD')"
        :class="[type === 'UPLOAD' ? 'border-brandPurple' : 'options-border']"
      >
        <!-- <div class="items-center"> -->
        <div class="px-4">
          <p class="text-lg font-semibold text-dark">
            Employment <br />
            documents
          </p>
          <p class="text-xs md:text-sm text-grey">
            We will verify your employment manually. This will take about 48
            hours.
          </p>
        </div>
        <!-- </div> -->
      </div>
    </div>
    <Button
      text="Continue"
      :shadow="true"
      width="w-full"
      p="px-3 py-4"
      class="mt-6 place-self-end"
      :disabled="disableButton"
      @click="$emit('select')"
    />
    <!-- <p class="text-sm text-grey text-center mt-4">
      If we verify your employment manually, we might have to contact your
      employer to confirm your employment status
    </p> -->
  </div>
</template>
<script>
  // import { mapState } from "vuex";
  import Hr from "@/UI/Hr";
  import { Modal } from "@/UI/Modals";
  import { Button } from "@/UI/Button";
  import { BackNav } from "@/UI/Navigation";
  export default {
    props: {
      display: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: "",
      },
      company: {
        type: String,
        default: "",
      },
    },
    components: {
      Modal,
      Hr,
      Button,
      BackNav,
    },
    computed: {
      states() {
        return this.location.map((location) => location.state);
      },
      rules() {
        return {
          type: this.type === "EMAIL" || this.type === "UPLOAD",
        };
      },
      disableButton() {
        return Object.values(this.rules).includes(false);
      },
    },
  };
</script>
<style scoped>
  .dot {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid #d5ccff;
  }
  .dot-active {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid #d5ccff;
    padding: 2px;
  }
  .style-container {
    display: grid;
    grid-template-columns: 85% 1fr;
    grid-gap: 10px;
    align-content: center;
  }
  .card-options {
    height: 14.8rem;
    text-align: center;
    display: grid;
    align-content: center;
  }
  .options-border {
    border: 1px solid rgba(105, 86, 200, 0.25);
  }
  @media (max-width: 768px) {
    .card-options {
      height: 10rem;
    }
  }
</style>
