var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scrollbar" },
    [
      _c("BackNav", {
        on: {
          click: function ($event) {
            return _vm.$emit("back")
          },
        },
      }),
      _c(
        "h5",
        {
          staticClass: "md:text-2xl text-xl text-center font-bold primary-text",
        },
        [_vm._v(" Let’s verify you work for " + _vm._s(_vm.company) + " ")]
      ),
      _c("p", { staticClass: "text-center text-grey" }, [
        _vm._v("Choose your verification method"),
      ]),
      _c("div", { staticClass: "grid md:grid-cols-2 gap-4 mt-4" }, [
        _c(
          "div",
          {
            staticClass: "cursor-pointer card-options border rounded-lg",
            class: [
              _vm.type === "EMAIL" ? "border-brandPurple" : "options-border",
            ],
            on: {
              click: function ($event) {
                return _vm.$emit("verificationType", "EMAIL")
              },
            },
          },
          [_vm._m(0)]
        ),
        _c(
          "div",
          {
            staticClass: "cursor-pointer card-options border rounded-lg",
            class: [
              _vm.type === "UPLOAD" ? "border-brandPurple" : "options-border",
            ],
            on: {
              click: function ($event) {
                return _vm.$emit("verificationType", "UPLOAD")
              },
            },
          },
          [_vm._m(1)]
        ),
      ]),
      _c("Button", {
        staticClass: "mt-6 place-self-end",
        attrs: {
          text: "Continue",
          shadow: true,
          width: "w-full",
          p: "px-3 py-4",
          disabled: _vm.disableButton,
        },
        on: {
          click: function ($event) {
            return _vm.$emit("select")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "px-4" }, [
      _c("p", { staticClass: "text-lg font-semibold text-dark" }, [
        _vm._v("Work email"),
      ]),
      _c("p", { staticClass: "text-xs md:text-sm text text-grey" }, [
        _vm._v(" You will be prompted to confirm your work email with an OTP "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "px-4" }, [
      _c("p", { staticClass: "text-lg font-semibold text-dark" }, [
        _vm._v(" Employment "),
        _c("br"),
        _vm._v(" documents "),
      ]),
      _c("p", { staticClass: "text-xs md:text-sm text-grey" }, [
        _vm._v(
          " We will verify your employment manually. This will take about 48 hours. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }