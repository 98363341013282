var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-full grid p-2 w-full relative" }, [
    !_vm.showOtp
      ? _c(
          "div",
          [
            _c("BackNav", {
              on: {
                click: function ($event) {
                  return _vm.$emit("back")
                },
              },
            }),
            _c(
              "h5",
              {
                staticClass:
                  "md:text-2xl text-xl text-center font-bold primary-text",
              },
              [_vm._v(" Work Information ")]
            ),
            _c("p", { staticClass: "text-center text-grey" }, [
              _vm._v("Enter work email"),
            ]),
            _c(
              "div",
              { staticClass: "relative" },
              [
                _c("Input", {
                  staticClass: "mt-14",
                  attrs: {
                    placeholder: "What's your work email",
                    width: "w-full",
                    validation: _vm.rules.email,
                    errorText: "Please enter a valid email",
                  },
                  model: {
                    value: _vm.user.workEmail,
                    callback: function ($$v) {
                      _vm.$set(_vm.user, "workEmail", $$v)
                    },
                    expression: "user.workEmail",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "bg-lightBlue px-4 py-6 my-6 rounded-lg" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex justify-between text-blue text-sm md:text-base",
                  },
                  [
                    _c("p", [_vm._v("Why we need your work email?")]),
                    _c(
                      "div",
                      {
                        staticClass: "flex items-center",
                        on: {
                          click: function ($event) {
                            _vm.showWhy = !_vm.showWhy
                          },
                        },
                      },
                      [
                        !_vm.showWhy
                          ? _c("p", { staticClass: "mr-2 cursor-pointer" }, [
                              _vm._v("Show"),
                            ])
                          : _c("p", { staticClass: "mr-2 cursor-pointer" }, [
                              _vm._v("Hide"),
                            ]),
                        _c(
                          "svg",
                          {
                            staticClass: "transition-all",
                            class: { rotate: _vm.showWhy },
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "8",
                              height: "6",
                              viewBox: "0 0 8 6",
                              fill: "none",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M1.41315 1.19332L3.99982 3.77999L6.58648 1.19332C6.84649 0.933322 7.26649 0.933322 7.52649 1.19332C7.78649 1.45332 7.78649 1.87332 7.52649 2.13332L4.46649 5.19332C4.20648 5.45332 3.78648 5.45332 3.52648 5.19332L0.466484 2.13332C0.206484 1.87332 0.206484 1.45332 0.466484 1.19332C0.726484 0.939988 1.15315 0.933322 1.41315 1.19332Z",
                                fill: "#0EA2F5",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm.showWhy
                  ? _c(
                      "div",
                      { staticClass: "text-dark" },
                      [
                        _c("Hr", { staticClass: "mt-4" }),
                        _vm._m(0),
                        _c("Hr", { staticClass: "mb-4" }),
                        _vm._m(1),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
            _c("div", { staticClass: "flex items-end mb-6" }, [
              _c(
                "div",
                { staticClass: "w-full" },
                [
                  _c("Button", {
                    attrs: {
                      text: "Continue",
                      width: "w-full",
                      loading: _vm.employLoading,
                      disabled: _vm.disableButton,
                    },
                    on: { click: _vm.submitEmploymentData },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        )
      : _c(
          "div",
          [
            _c("BackNav", {
              on: {
                click: function ($event) {
                  _vm.showOtp = false
                },
              },
            }),
            _c(
              "h5",
              {
                staticClass:
                  "md:text-2xl text-xl text-center font-bold primary-text",
              },
              [_vm._v(" We have sent an OTP to your work email ")]
            ),
            _c("p", { staticClass: "text-center text-grey" }, [
              _vm._v(
                " We sent an OTP to " +
                  _vm._s(_vm.employmentData.workEmail) +
                  ". "
              ),
              _c("br"),
              _c(
                "span",
                {
                  staticClass: "cursor-pointer text-brandPurple",
                  on: {
                    click: function ($event) {
                      _vm.showOtp = false
                    },
                  },
                },
                [_vm._v("Incorrect email? Click here to change")]
              ),
            ]),
            _c(
              "div",
              { staticClass: "relative" },
              [
                _c(
                  "div",
                  { staticClass: "mx-auto" },
                  [
                    _c("OtpInput", {
                      staticClass: "mt-8",
                      on: {
                        "on-change": _vm.handleOnChange,
                        "on-complete": _vm.handleOnChange,
                      },
                    }),
                  ],
                  1
                ),
                _vm.resendLoading
                  ? _c("Spinner")
                  : _c("div", { staticClass: "mt-4 flex" }, [
                      _vm.showResendButton
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "underline text-sm text-brandPurple cursor-pointer",
                              on: { click: _vm.resendOtp },
                            },
                            [_vm._v(" Resend OTP ")]
                          )
                        : _c(
                            "div",
                            { staticClass: "ml-auto" },
                            [
                              _c("vue-countdown", {
                                attrs: { time: 60 * 1000 },
                                on: {
                                  end: function ($event) {
                                    _vm.showResendButton = true
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var minutes = ref.minutes
                                      var seconds = ref.seconds
                                      return [
                                        _c(
                                          "span",
                                          { staticClass: "text-sm text-grey" },
                                          [
                                            _vm._v(
                                              _vm._s(minutes) +
                                                ":" +
                                                _vm._s(seconds)
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                    ]),
              ],
              1
            ),
            _c("Button", {
              staticClass: "mb-6 mt-14",
              attrs: {
                text: "Verify Employment",
                width: "w-full",
                loading: _vm.otpLoading,
                disabled: _vm.otp.length !== 6,
              },
              on: { click: _vm.submitOtp },
            }),
            _c("div", { staticClass: "spam-notice mb-4 mt-10" }, [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "20",
                    height: "20",
                    viewBox: "0 0 20 20",
                    fill: "none",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z",
                      fill: "#0EA2F5",
                    },
                  }),
                ]
              ),
              _c("p", { staticClass: "text-sm" }, [
                _vm._v(
                  " If you don’t see our email in your inbox, please check your spam folder "
                ),
              ]),
            ]),
          ],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "py-4" }, [
      _c("p", { staticClass: "pb-4" }, [
        _vm._v(
          " We need your work email to verify that you work where you say you work. "
        ),
      ]),
      _c("p", { staticClass: "font-semibold mb-2" }, [
        _vm._v(" What we don't do with your work email "),
      ]),
      _c("ul", { staticClass: "list-disc px-4 text-sm" }, [
        _c("li", [_vm._v("We don't contact your employer")]),
        _c("li", [
          _vm._v(
            " We don't send you emails there, we send to your personal mail "
          ),
        ]),
        _c("li", [_vm._v("We will never contact you through it")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" Read our "),
      _c(
        "a",
        {
          staticClass: "text-brandPurple",
          attrs: {
            href: "https://usezilla.notion.site/usezilla/Terms-of-use-3e19b68f1ac94c24aaf773369e2f3566",
          },
        },
        [_vm._v("terms and condition")]
      ),
      _vm._v(" to see how we process your data. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }