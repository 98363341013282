var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "p-2 w-full relative" }, [
    _c(
      "div",
      [
        _c("BackNav", {
          nativeOn: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.$emit("back")
            },
          },
        }),
        !_vm.showUpload
          ? _c("div", [
              _c(
                "h5",
                {
                  staticClass:
                    "md:text-2xl text-xl md:text-center font-bold primary-text",
                },
                [_vm._v(" Upload work documents ")]
              ),
              _c("p", { staticClass: "md:text-center text-grey text-lg" }, [
                _vm._v(
                  " Please upload at least ONE of the following. (Not older than 3 months) "
                ),
              ]),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "mt-14" },
          _vm._l(_vm.allowedDocuments, function (type, index) {
            return _c(
              "div",
              { key: index },
              [
                _c("UploadComponent", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        type === _vm.documentType || _vm.documentType === "",
                      expression:
                        "type === documentType || documentType === ''",
                    },
                  ],
                  attrs: { name: type, type: type },
                  on: {
                    uploaded: _vm.updateDocuments,
                    deleted: _vm.deleteDocuments,
                    hideUpload: _vm.showUploadComponent,
                    cancel: _vm.hideUploadComponent,
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
        !_vm.showUpload
          ? _c("div", [
              _c(
                "div",
                { staticClass: "bg-lightBlue px-4 py-6 my-6 rounded-lg" },
                [
                  _c("div", { staticClass: "flex justify-between text-blue" }, [
                    _c("p", [_vm._v("Why we need your work documents?")]),
                    _c(
                      "div",
                      {
                        staticClass: "flex items-center",
                        on: {
                          click: function ($event) {
                            _vm.showWhy = !_vm.showWhy
                          },
                        },
                      },
                      [
                        !_vm.showWhy
                          ? _c("p", { staticClass: "mr-2 cursor-pointer" }, [
                              _vm._v("Show"),
                            ])
                          : _c("p", { staticClass: "mr-2 cursor-pointer" }, [
                              _vm._v("Hide"),
                            ]),
                        _c(
                          "svg",
                          {
                            staticClass: "transition-all",
                            class: { rotate: _vm.showWhy },
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "8",
                              height: "6",
                              viewBox: "0 0 8 6",
                              fill: "none",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M1.41315 1.19332L3.99982 3.77999L6.58648 1.19332C6.84649 0.933322 7.26649 0.933322 7.52649 1.19332C7.78649 1.45332 7.78649 1.87332 7.52649 2.13332L4.46649 5.19332C4.20648 5.45332 3.78648 5.45332 3.52648 5.19332L0.466484 2.13332C0.206484 1.87332 0.206484 1.45332 0.466484 1.19332C0.726484 0.939988 1.15315 0.933322 1.41315 1.19332Z",
                                fill: "#0EA2F5",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _vm.showWhy
                    ? _c(
                        "div",
                        { staticClass: "text-dark" },
                        [
                          _c("Hr", { staticClass: "mt-4" }),
                          _c("div", { staticClass: "py-4" }, [
                            _vm._v(
                              " To confirm that you currently work at your present workplace. "
                            ),
                          ]),
                          _c("Hr", { staticClass: "mb-4" }),
                          _vm._m(0),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _c("div", { staticClass: "flex items-end mb-6" }, [
                _c(
                  "div",
                  { staticClass: "w-full" },
                  [
                    _c("Button", {
                      attrs: {
                        text: "Proceed",
                        width: "w-full",
                        loading: _vm.employLoading,
                        disabled: _vm.disableButton,
                      },
                      on: { click: _vm.submitEmploymentData },
                    }),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" Read our "),
      _c(
        "a",
        {
          staticClass: "text-brandPurple",
          attrs: {
            href: "https://usezilla.notion.site/usezilla/Terms-of-use-3e19b68f1ac94c24aaf773369e2f3566",
          },
        },
        [_vm._v("terms and condition")]
      ),
      _vm._v(" to see how we process your data. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }