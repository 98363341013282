<template>
  <div class="p-2 w-full relative">
    <div>
      <BackNav @click.native.stop="$emit('back')" />

      <div v-if="!showUpload">
        <h5 class="md:text-2xl text-xl md:text-center font-bold primary-text">
          Upload work documents
        </h5>
        <p class="md:text-center text-grey text-lg">
          Please upload at least ONE of the following. (Not older than 3 months)
        </p>
      </div>

      <div class="mt-14">
        <div v-for="(type, index) in allowedDocuments" :key="index">
          <UploadComponent
            v-show="type === documentType || documentType === ''"
            :name="type"
            :type="type"
            @uploaded="updateDocuments"
            @deleted="deleteDocuments"
            @hideUpload="showUploadComponent"
            @cancel="hideUploadComponent"
          />
        </div>
      </div>

      <div v-if="!showUpload">
        <div class="bg-lightBlue px-4 py-6 my-6 rounded-lg">
          <div class="flex justify-between text-blue">
            <p>Why we need your work documents?</p>
            <div class="flex items-center" @click="showWhy = !showWhy">
              <p class="mr-2 cursor-pointer" v-if="!showWhy">Show</p>
              <p class="mr-2 cursor-pointer" v-else>Hide</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="6"
                viewBox="0 0 8 6"
                fill="none"
                class="transition-all"
                :class="{ rotate: showWhy }"
              >
                <path
                  d="M1.41315 1.19332L3.99982 3.77999L6.58648 1.19332C6.84649 0.933322 7.26649 0.933322 7.52649 1.19332C7.78649 1.45332 7.78649 1.87332 7.52649 2.13332L4.46649 5.19332C4.20648 5.45332 3.78648 5.45332 3.52648 5.19332L0.466484 2.13332C0.206484 1.87332 0.206484 1.45332 0.466484 1.19332C0.726484 0.939988 1.15315 0.933322 1.41315 1.19332Z"
                  fill="#0EA2F5"
                />
              </svg>
            </div>
          </div>

          <div class="text-dark" v-if="showWhy">
            <Hr class="mt-4" />
            <div class="py-4">
              To confirm that you currently work at your present workplace.
            </div>
            <Hr class="mb-4" />
            <p>
              Read our
              <a
                href="https://usezilla.notion.site/usezilla/Terms-of-use-3e19b68f1ac94c24aaf773369e2f3566"
                class="text-brandPurple"
                >terms and condition</a
              >
              to see how we process your data.
            </p>
          </div>
        </div>
        <div class="flex items-end mb-6">
          <div class="w-full">
            <Button
              text="Proceed"
              width="w-full"
              :loading="employLoading"
              :disabled="disableButton"
              @click="submitEmploymentData"
            />
            <!-- <p class="text-sm text-grey text-center mt-4">
              If we verify your employment manually, we might have to contact
              your employer to confirm your employment status
            </p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapState } from "vuex";
  import { GET_ALLOWED_DOCUMENTS } from "@/utils/api/verification.js";
  // import { RESEND_EMPLOYMENT_OTP } from "@/utils/api/userInfo.js";
  import { Input, Select } from "@/UI/Input";
  import { Button } from "@/UI/Button";
  import Hr from "@/UI/Hr";
  import UploadComponent from "./UploadComponent.vue";
  import { BackNav } from "@/UI/Navigation";
  export default {
    components: {
      Input,
      Button,
      Select,
      Spinner: () => import("@/UI/Spinner"),
      Hr,
      UploadComponent,
      BackNav,
    },
    
    props: {
      employmentData: {
        type: Object,
        default: () => {},
        required: false,
      },
      requestMethod: {
        type: String,
        default: "",
        required: true,
      }
    },

    data: () => ({
      employLoading: false,
      displayLoaderScreen: false,
      showWhy: false,
      documents: [],
      allowedDocuments: {},
      showUpload: false,
      documentType: "",
      user: {},
    }),

    computed: {
      states() {
        return this.location.map((location) => location.state);
      },
      rules() {
        return {
          documents: this.documents.length > 0,
        };
      },
      disableButton() {
        return Object.values(this.rules).includes(false);
      },
    },

    mounted() {
      this.getAllowedDocuments();
      this.user = this.employmentData;
    },

    methods: {
      ...mapActions("userInfo", ["employmentInfo", "addEmploymentInfo"]),
      ...mapActions("loading", ["setLoading"]),
      updateDocuments(...value) {
        this.showUpload = false;
        this.documentType = "";
        let item = this.documents.find((o) => o.type === value[1]);
        if (item) {
          item.id = value[0];
          item.type = value[1];
        } else {
          this.documents.push({
            id: value[0],
            type: value[1],
          });
        }
      },
      deleteDocuments(value) {
        this.documents.splice(
          this.documents.findIndex((item) => item.type === value),
          1
        );
      },
      showUploadComponent(val) {
        this.showUpload = true;
        this.documentType = val;
      },
      hideUploadComponent() {
        this.showUpload = false;
        this.documentType = "";
      },
      submitEmploymentData() {
        this.employmentData.documents = this.documents;
        this.employLoading = true;
        delete this.user.workEmail;
        this.addEmploymentInfo({
          method: this.requestMethod,
          data: this.user,
        })
          .then(({ data }) => {
            this.$emit("next");
            this.employLoading = false;
          })
          .catch(() => {
            this.employLoading = false;
          });
      },
      getAllowedDocuments() {
        this.setLoading(true);
        GET_ALLOWED_DOCUMENTS()
          .then((res) => {
            this.setLoading(false);
            this.allowedDocuments = res.data.data;
          })
          .catch(() => {
            this.setLoading(false);
          });
      },
    },
  };
</script>
<style scoped>
  .spinner {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    z-index: 5;
  }
  .rotate {
    transform: rotate(180deg);
  }
</style>
