var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.showUploadModal
      ? _c(
          "div",
          [
            _c("div", { staticClass: "options justify-between my-6" }, [
              _c("p", { staticClass: "font-semibold mr-6" }, [
                _vm._v(" " + _vm._s(_vm.name) + " "),
                _c("span", { staticClass: "text-lightGrey-2 font-normal" }, [
                  _vm._v(" " + _vm._s(_vm._f("truncate")(_vm.fileName, 30))),
                ]),
              ]),
              _vm.fileName === ""
                ? _c(
                    "svg",
                    {
                      staticClass: "cursor-pointer",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "14",
                        height: "14",
                        viewBox: "0 0 14 14",
                        fill: "none",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.triggerUpload()
                        },
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M13 8H8V13C8 13.55 7.55 14 7 14C6.45 14 6 13.55 6 13V8H1C0.45 8 0 7.55 0 7C0 6.45 0.45 6 1 6H6V1C6 0.45 6.45 0 7 0C7.55 0 8 0.45 8 1V6H13C13.55 6 14 6.45 14 7C14 7.55 13.55 8 13 8Z",
                          fill: "#918E9C",
                        },
                      }),
                    ]
                  )
                : _c(
                    "svg",
                    {
                      staticClass: "cursor-pointer",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "19",
                        height: "19",
                        viewBox: "0 0 19 19",
                        fill: "none",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.triggerUpload()
                        },
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M0 15.4601V18.5001C0 18.7801 0.22 19.0001 0.5 19.0001H3.54C3.67 19.0001 3.8 18.9501 3.89 18.8501L14.81 7.94006L11.06 4.19006L0.15 15.1001C0.0500001 15.2001 0 15.3201 0 15.4601ZM17.71 5.04006C18.1 4.65006 18.1 4.02006 17.71 3.63006L15.37 1.29006C14.98 0.900059 14.35 0.900059 13.96 1.29006L12.13 3.12006L15.88 6.87006L17.71 5.04006Z",
                          fill: "#918E9C",
                        },
                      }),
                    ]
                  ),
            ]),
            _c("Hr"),
          ],
          1
        )
      : _vm._e(),
    _vm.showUploadModal
      ? _c(
          "div",
          [
            _c(
              "h2",
              {
                staticClass:
                  "mt-6 md:text-2xl text-xl font-bold primary-text text-center",
              },
              [_vm._v(" Upload " + _vm._s(_vm.name) + " ")]
            ),
            _c("Upload", {
              staticClass: "mt-10",
              attrs: {
                fileType: ["jpeg", "jpg", "png", "pdf"],
                text: _vm.fileName,
              },
              on: {
                upload: _vm.handleUpload,
                deleted: _vm.handleDelete,
                fileUploaded: _vm.updateFileName,
              },
            }),
            _c("Button", {
              staticClass: "mt-10 place-self-end",
              attrs: {
                text: "Proceed",
                shadow: true,
                width: "w-full",
                p: "px-3 py-4",
                disabled: _vm.disableButton,
              },
              on: {
                click: function ($event) {
                  return _vm.proceed()
                },
              },
            }),
            _c("SecondaryButton", {
              staticClass: "mt-2 place-self-end font-semibold",
              attrs: {
                text: "Cancel",
                width: "w-full",
                p: "px-3 py-4",
                background: "bg-transparent",
                color: "text-dark",
                fontSize: "text-base",
              },
              on: { click: _vm.cancel },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }