<template>
  <div class="h-full grid p-2 w-full relative">
    <div v-if="!showOtp">
      <BackNav @click="$emit('back')" />
      <h5 class="md:text-2xl text-xl text-center font-bold primary-text">
        Work Information
      </h5>
      <p class="text-center text-grey">Enter work email</p>

      <div class="relative">
        <Input
          placeholder="What's your work email"
          class="mt-14"
          v-model="user.workEmail"
          width="w-full"
          :validation="rules.email"
          errorText="Please enter a valid email"
        />
        <!-- <Spinner class="spinner" v-if="workEmailLoading" /> -->
      </div>

      <div class="bg-lightBlue px-4 py-6 my-6 rounded-lg">
        <div class="flex justify-between text-blue text-sm md:text-base">
          <p>Why we need your work email?</p>
          <div class="flex items-center" @click="showWhy = !showWhy">
            <p class="mr-2 cursor-pointer" v-if="!showWhy">Show</p>
            <p class="mr-2 cursor-pointer" v-else>Hide</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="6"
              viewBox="0 0 8 6"
              fill="none"
              class="transition-all"
              :class="{ rotate: showWhy }"
            >
              <path
                d="M1.41315 1.19332L3.99982 3.77999L6.58648 1.19332C6.84649 0.933322 7.26649 0.933322 7.52649 1.19332C7.78649 1.45332 7.78649 1.87332 7.52649 2.13332L4.46649 5.19332C4.20648 5.45332 3.78648 5.45332 3.52648 5.19332L0.466484 2.13332C0.206484 1.87332 0.206484 1.45332 0.466484 1.19332C0.726484 0.939988 1.15315 0.933322 1.41315 1.19332Z"
                fill="#0EA2F5"
              />
            </svg>
          </div>
        </div>

        <div class="text-dark" v-if="showWhy">
          <Hr class="mt-4" />
          <div class="py-4">
            <p class="pb-4">
              We need your work email to verify that you work where you say you
              work.
            </p>
            <p class="font-semibold mb-2">
              What we don't do with your work email
            </p>
            <ul class="list-disc px-4 text-sm">
              <li>We don't contact your employer</li>
              <li>
                We don't send you emails there, we send to your personal mail
              </li>
              <li>We will never contact you through it</li>
            </ul>
          </div>
          <Hr class="mb-4" />
          <p>
            Read our
            <a
              href="https://usezilla.notion.site/usezilla/Terms-of-use-3e19b68f1ac94c24aaf773369e2f3566"
              class="text-brandPurple"
              >terms and condition</a
            >
            to see how we process your data.
          </p>
        </div>
      </div>
      <div class="flex items-end mb-6">
        <div class="w-full">
          <Button
            text="Continue"
            width="w-full"
            :loading="employLoading"
            :disabled="disableButton"
            @click="submitEmploymentData"
          />
          <!-- <p class="text-sm text-grey text-center mt-4">
            If we verify your employment manually, we might have to contact your
            employer to confirm your employment status
          </p> -->
        </div>
      </div>
    </div>
    <!-- modal for otp -->
    <div v-else>
      <BackNav @click="showOtp = false" />
      <h5 class="md:text-2xl text-xl text-center font-bold primary-text">
        We have sent an OTP to your work email
      </h5>
      <p class="text-center text-grey">
        We sent an OTP to {{ employmentData.workEmail }}. <br />
        <span class="cursor-pointer text-brandPurple" @click="showOtp = false"
          >Incorrect email? Click here to change</span
        >
      </p>
      <div class="relative">
        <!-- <Input
          class="mt-8"
          placeholder="Enter OTP"
          width="w-full"
          v-model="otp"
          type="number"
          hideErrorIcon
          errorText="OTP must be 6 characters long"
          :validation="otp.length === 6"
        /> -->
        <div class="mx-auto">
          <OtpInput
            class="mt-8"
            @on-change="handleOnChange"
            @on-complete="handleOnChange"
          />
        </div>
        <Spinner v-if="resendLoading" />
        <div class="mt-4 flex" v-else>
          <p
            class="underline text-sm text-brandPurple cursor-pointer"
            v-if="showResendButton"
            @click="resendOtp"
          >
            Resend OTP
          </p>
          <div class="ml-auto" v-else>
            <vue-countdown
              :time="60 * 1000"
              v-slot="{ minutes, seconds }"
              @end="showResendButton = true"
            >
              <span class="text-sm text-grey">{{ minutes }}:{{ seconds }}</span>
            </vue-countdown>
          </div>
        </div>

        <!-- <SecondaryButton
          v-if="showResendButton"
          class="otp-button z-10 mb-1"
          text="Resend OTP"
          :loading="resendLoading"
          width="w-28"
          @click="resendOtp"
        /> -->
      </div>

      <Button
        text="Verify Employment"
        width="w-full"
        @click="submitOtp"
        class="mb-6 mt-14"
        :loading="otpLoading"
        :disabled="otp.length !== 6"
      />
      <div class="spam-notice mb-4 mt-10">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
            fill="#0EA2F5"
          />
        </svg>
        <p class="text-sm">
          If you don’t see our email in your inbox, please check your spam
          folder
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions } from "vuex";
import { OtpInput } from "@/UI/Input";
import { BackNav } from "@/UI/Navigation";
import {
  SUBMIT_EMPLOYMENT_OTP,
  RESEND_EMPLOYMENT_OTP,
} from "@/utils/api/userInfo.js";

import { GET_WORK_EMAIL } from "@/utils/api/verification.js";
import { Input, Select } from "@/UI/Input";
import { Button, SecondaryButton } from "@/UI/Button";
import Hr from "@/UI/Hr";
import { Modal } from "@/UI/Modals";
import VueCountdown from "@chenfengyuan/vue-countdown";
Vue.component("vue-countdown", VueCountdown);

export default {
  components: {
    Input,
    Button,
    SecondaryButton,
    Select,
    BackNav,
    Spinner: () => import("@/UI/Spinner"),
    Hr,
    Modal,
    OtpInput,
  },
  props: {
    employmentData: {
      type: Object,
      default: () => {},
      required: false,
    },
    requestMethod: {
      type: String,
      default: "",
      required: true,
    }
  },
  data: () => ({
    workEmailLoading: false,
    employLoading: false,
    otpLoading: false,
    displayLoaderScreen: false,
    showWhy: false,
    showOtp: false,
    hasSubmittedOtp: false,
    showResendButton: false,
    otp: "",
    resendLoading: false,
    user: {
      workEmail: "",
    },
  }),
  computed: {
    states() {
      return this.location.map((location) => location.state);
    },
    rules() {
      return {
        email:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            this.user.workEmail?.trim()
          ),
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
  },
  mounted() {
    this.user = this.employmentData;
  },
  methods: {
    ...mapActions("userInfo", [
      "employmentInfo",
      "addEmploymentInfo",
      "setWorkEmail",
    ]),
    ...mapActions("notification", ["showToast"]),
    ...mapActions("loading", ["setLoading"]),
    handleOnChange(value) {
      this.otp = value;
    },
    submitEmploymentData() {
      this.employLoading = true;
      this.addEmploymentInfo({
        method: this.requestMethod,
        data: this.user,
      })
        .then(({ data }) => {
          if (data.data.workEmailVerifiedAt == null) {
            this.showOtp = true;
            this.handleDelayOtpButton();
          } else {
            this.$emit("next");
          }
          this.employLoading = false;
        })
        .catch(() => {
          this.employLoading = false;
        });
    },
    submitOtp() {
      this.otpLoading = true;
      SUBMIT_EMPLOYMENT_OTP(this.otp)
        .then((res) => {
          this.otpLoading = false;
          this.showOtp = false;
          this.hasSubmittedOtp = true;
          setTimeout(() => {
            this.$emit("next");
          }, 300);
        })
        .catch(() => {
          this.otpLoading = false;
        });
    },
    resendOtp() {
      return new Promise((resolve, reject) => {
        this.resendLoading = true;
        RESEND_EMPLOYMENT_OTP()
          .then((res) => {
            this.resendLoading = false;
            this.showResendButton = false;
            this.handleDelayOtpButton();
            this.showToast({
              display: true,
              description: "A new OTP has been sent to your email",
              icon: "success",
            });
            resolve(res);
          })
          .catch((err) => {
            this.resendLoading = false;
            reject(err);
          });
      });
    },
    handleDelayOtpButton() {
      setTimeout(() => {
        this.showResendButton = true;
      }, 60000);
    },
  },
};
</script>
<style scoped>
.spinner {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 5;
}
.rotate {
  transform: rotate(180deg);
}
.otp-button {
  position: absolute;
  right: 0;
  top: 0.5rem;
}
</style>
