<template>
  <div class="relative">
    <!-- <transition name="slideUp" mode="out-in"> -->
    <div class="container-body__surround scrollbar" v-show="showEmploymentType">
      <div class="container-body__content overflow-hidden lg:w-9/12 mx-auto grid">
        <transition name="slideX" mode="out-in">
          <div class="flex flex-col justify-center">
            <div class="w-full" v-if="showStep === 'form'">
              <BackNav @click="showEmploymentType = false" />
              <h5
                class="md:text-2xl text-xl text-center font-bold primary-text leading-10"
              >
                {{ noEmployment ? "Update" : "Provide" }} your work information
              </h5>
              <p
                class="text-center font-semibold refunded"
                v-if="employmentData.verificationStatus"
              >
                Verification
                {{ employmentData.verificationStatus.toLowerCase() }}!
              </p>
              <p
                class="text-center font-semibold refunded"
                v-if="employmentData.verificationStatus === 'EXPIRED'"
              >
                Please re-verify your employment details
              </p>
              <Input
                placeholder="Where do you work"
                class="mt-6"
                v-model="employmentData.employerName"
                width="w-full"
                :validation="rules.name"
                errorText="Please enter where you work"
                :disabled="disableInput"
              />

              <Input
                id="address"
                placeholder="What is the address of your workplace?"
                class="mt-8"
                v-model="employmentData.officeAddress"
                width="w-full"
                @blur="validateAddress()"
                @focus="handleError()"
                :disabled="disableInput"
              />
              <transition name="fade-out">
                <p v-if="errorOnAddress" class="text-brandRed text-xs">
                  Please select a valid address from the dropdown
                </p>
              </transition>

              <Input
                placeholder="Add more details to this address (if any)"
                class="mt-8"
                v-model="employmentData.additionalAddressDetails"
                width="w-full"
                :disabled="disableInput"
              />

              <div class="mt-8 grid grid-cols-2 gap-4">
                <Select
                  placeholder="State"
                  label="name"
                  :options="states"
                  :reduce="(category) => category.name"
                  v-model="employmentData.state"
                  :disabled="disableInput"
                />
                <Select
                  placeholder="LGA"
                  :options="lga"
                  v-model="employmentData.lga"
                  :reduce="(category) => category.name"
                  label="name"
                  :disabled="disableInput"
                />
              </div>
              <div class="mt-8" v-if="disableInput">
                <Button
                  text="Edit Information"
                  class="mt-8 w-full mb-2"
                  @click="disableInput = false"
                />
                <!-- <p
                  class="text-font-semibold text-brandPurple text-center mt-4 cursor-pointer font-semibold"
                  @click="handleClose"
                >
                  Skip, I will do it later
                </p> -->
              </div>

              <div v-else>
                <Button
                  text="Verify Employment"
                  class="mt-8 w-full mb-2"
                  @click="showStep = 'verification'"
                  :disabled="disableButton"
                />
                <p
                  class="text-font-semibold text-brandPurple text-center my-4 text-sm cursor-pointer"
                  @click="handleClose"
                >
                  Skip, I will do it later
                </p>
              </div>
            </div>
          
            <div class="overflow-hidden">
              <transition name="slideX" mode="out-in">
                <VerificationOptions
                v-if="showStep === 'verification'"
                @verificationType="chooseVerification"
                :type="type"
                :company="employmentData.employerName"
                @select="selected"
                @back="showStep = 'form'"
              />
              <!-- <transition name="slideX" mode="out-in"> -->
                <EmailVerification
                  :employmentData="employmentData"
                  :requestMethod="requestMethod"
                  v-if="showStep === 'email'"
                  @next="$emit('email')"
                  @back="showStep = 'verification'"
                />
              <!-- </transition> -->
              <!-- <transition name="slideX" mode="out-in"> -->
                <UploadVerification
                  v-if="showStep === 'upload'"
                  :requestMethod="requestMethod"
                  @next="$emit('documents')"
                  @back="showStep = 'verification'"
                  :employmentData="employmentData"
                />
              </transition>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <EmploymentType
      v-if="!showEmploymentType"
      @next="showEmploymentType = true"
    />

    <!-- <Modal
      :display="employmentData.verificationStatus === 'PENDING'"
      title="Here's what happened the last time"
      @close="handleClose"
    >
      <p class="text-grey text-center md:text-left">
        We are still verifying your employment details; please chack back later
      </p>

      <Button text="Close" class="mt-12" width="w-full" @click="handleClose" />
    </Modal> -->
    <!-- </transition> -->
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { Input, Select, Upload } from "@/UI/Input";
import { Button, SecondaryButton } from "@/UI/Button";
import { Modal } from "@/UI/Modals";
import { BackNav } from "@/UI/Navigation";
import Location from "@/database/location.json";
import VerificationOptions from "@/components/Home/ActivateZilla/Employment/VerificationOptions.vue";
import EmailVerification from "@/components/Home/ActivateZilla/Employment/EmailVerification.vue";
import UploadVerification from "@/components/Home/ActivateZilla/Employment/UploadVerification.vue";
import {
  EMAIL_VALIDATION_STATUS,
  EMPLOYMENT_DOCUMENT_STATUS,
} from "@/components/Home/ActivateZilla/Employment/data";

export default {
  components: {
    Input,
    Button,
    Modal,
    Upload,
    Select,
    BackNav,
    SecondaryButton,
    VerificationOptions,
    EmailVerification,
    UploadVerification,
    Spinner: () => import("@/UI/Spinner"),
    EmploymentType: () => import("./EmploymentType"),
  },
  data: () => ({
    disableInput: false,
    showStep: "form",
    setFieldDisbaled: false,
    type: "",
    workEmailLoading: false,
    // requestMethod: "post",
    location: Location,
    lga: [],
    employmentData: {
      employerName: "",
      officeAddress: "",
      employerWebsite: "",
      workEmail: "",
      proofOfEmploymentImageId: "",
      additionalAddressDetails: "",
      id: "",
      state: "",
      lga: "",
      verificationStatus: null,
    },
    isAddressValid: false,
    errorOnAddress: false,
    noEmployment: false,
    showEmploymentType: false,
    // showPending: "",
  }),
  computed: {
    ...mapState({
      employmentCreditQualification: (state) =>
        state?.userInfo?.creditQualification?.preConditionResult
          ?.EMPLOYMENT_STATUS_CHECK?.eligibilityStatus,
      loading: (state) => state.loading.loading,
      userEmploymentData: (state) => state.userInfo.userEmployementInfo,
    }),
    employmentAvailable(){
        return Object.keys(this.userEmploymentData).length
      },
      requestMethod(){
        return this.employmentAvailable ? 'put' : 'post'
      },
    states() {
      return this.location.map((location) => location.state);
    },
    rules() {
      return {
        name: this.employmentData.employerName.length >= 2,
        // email:
        //   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        //     this.employmentData.workEmail
        //   ),
        address: this.isAddressValid,
        state: this.employmentData.state.length > 0,
        lga: this.employmentData.lga.length > 0,
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
  },
  watch: {
    showEmploymentType(value) {
      if (value) {
        this.$nextTick(() => {
          this.googleAutocomplete();
        });
      }
    },
    "employmentData.state"(value) {
      this.location.forEach((state) => {
        if (state.state.name === value) {
          this.lga = state.state.locals;
        }
      });
    },
  },
  mounted() {
    if(Object.keys(this.userEmploymentData).length === 0){
      this.getEmploymentData();
    } else{
      this.setEmploymentData()
    }
    
  },
  methods: {
    ...mapActions("userInfo", ["employmentInfo"]),
    ...mapActions("loading", ["setLoading"]),
    ...mapActions("notification", ["showAlert"]),
    ...mapActions("auth", ["setEmploymentInfo"]),
    getEmploymentData() {
      this.setLoading(true);
      this.employmentInfo()
        .then(() => {
          this.setEmploymentData()
          // this.requestMethod = "put";
          // this.isAddressValid = true;
          // const data = res.data.data;
          // if (data?.verificationStatus.toLowerCase().trim() === "approved") {
          //   this.$emit("approved");
          // } else {
          //   this.employmentData.verificationStatus = data?.verificationStatus;
          // }

          // this.employmentData.employerName = data.employerName;
          // this.employmentData.officeAddress = data.officeAddress;
          // this.employmentData.workEmail =
          //   data?.employmentEmailAddress?.workEmail;
          // this.employmentData.id = data.id;
          // this.employmentData.state = data.state;
          // this.employmentData.lga = data.lga;
          // this.employmentData.additionalAddressDetails =
          //   data.additionalAddressDetails;
          // this.employmentData.proofOfEmploymentImageId =
          //   data.proofOfEmploymentImageId;
          // this.noEmployment = true;
          // this.disableInput = true;

          // if (data.verificationStatus === "PENDING") {
          //   this.handleEmploymentSteps(data);
          // }
          this.setLoading(false);
        })
        .catch(() => {
          this.setLoading(false);
        });
    },
    handleEmploymentSteps(data) {
      if (data.verificationType === "WORK_EMAIL") {
        if (
          data.employmentEmailAddress.status === EMAIL_VALIDATION_STATUS.pending
        ) {
          this.showStep = "email";
        } else if (
          data?.employmentEmailAddress?.status ===
          EMAIL_VALIDATION_STATUS.awaitingCompanyValidation
        ) {
          this.disableInput = true;
        }
      } else if (value.verificationType === "DOCUMENT") {
        if (
          value?.employmentDocument?.status ===
          EMPLOYMENT_DOCUMENT_STATUS.pending
        ) {
          this.showStep = "form";
          this.disableInput = true;
        } else if (
          value?.employmentDocument?.status ===
          EMPLOYMENT_DOCUMENT_STATUS?.declined
        ) {
          this.showStep = "form";
          this.disableInput = false;
        }
      }
    },
    setEmploymentData(){
      this.disableInput = true;
      this.isAddressValid = true;
      this.employmentData.employerName = this.userEmploymentData?.employerName;
      this.employmentData.officeAddress = this.userEmploymentData?.officeAddress;
      this.employmentData.workEmail = this.userEmploymentData?.employmentEmailAddress?.workEmail;
      this.employmentData.id = this.userEmploymentData?.id;
      this.employmentData.state = this.userEmploymentData?.state;
      this.employmentData.lga = this.userEmploymentData?.lga;
      this.employmentData.additionalAddressDetails = this.userEmploymentData?.additionalAddressDetails;
      this.employmentData.proofOfEmploymentImageId = this.userEmploymentData?.proofOfEmploymentImageId;
      if (this.userEmploymentData?.verificationStatus === "PENDING") {
            this.handleEmploymentSteps(data);
        }
      if (this.userEmploymentData?.verificationStatus.toLowerCase().trim() === "approved") {
          this.$emit("approved");
      } else {
        this.employmentData.verificationStatus = this.userEmploymentData?.verificationStatus;
      }
    },
    // google maps integration starts here
    validateAddress() {
      if (this.isAddressValid) {
        this.errorOnAddress = false;
        document.getElementById("address").style.borderColor = "#000";
      } else {
        this.errorOnAddress = true;
        document.getElementById("address").style.borderColor = "#C70039";
      }
    },

    handleError() {
      if (this.isAddressValid) {
        this.employmentData.officeAddress = "";
        this.employmentData.state = "";
        this.employmentData.lga = "";
      }
      document.getElementById("address").style.borderColor = "#000";
      this.errorOnAddress = false;
      this.isAddressValid = false;
    },
    googleAutocomplete() {
      // document.getElementById("address").placeholder = "";

      let options = {
        // types: ["geocode"],
        componentRestrictions: { country: "ng" },
      };
      this.autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("address"),
        options
      );
      this.autocomplete.addListener("place_changed", () => {
        this.isAddressValid = true;
        this.validateAddress();
        let place = this.autocomplete.getPlace();
        let ac = place.address_components;
        const street = {
          no: "",
          name: "",
          neighborhood: "",
        };

        ac.forEach((address) => {
          if (address.types[0] === "street_number") {
            street.no = address.long_name;
          }
          if (address.types[0] === "route") {
            street.name = address.long_name;
          }
          if (address.types[0] === "neighborhood") {
            street.neighborhood = address.long_name;
          }
          if (address.types[0] === "administrative_area_level_1") {
            if (address.long_name === "Federal Capital Territory") {
              address.long_name = "FCT";
            }
            this.employmentData.state = address.long_name.split(" State")[0];
          }
          if (address.types[0] === "administrative_area_level_2") {
            if (address.short_name === "AMAC") {
              address.long_name = "Municipal Area Council";
            }
            this.employmentData.lga = address.long_name;
          }
        });

        // this.employmentData.officeAddress =
        //   street.no + " " + street.name + ", " + street.neighborhood;
        this.employmentData.officeAddress = place.name;
        if (
          this.employmentData.state === "" ||
          this.employmentData.lga === ""
        ) {
          this.isAddressValid = false;
          this.showAlert({
            display: true,
            description:
              "Please select a valid street address from the dropdown",
            type: "error",
          });
        }
      });
    },
    // google maps integration ends here
    chooseVerification(value) {
      this.type = value;
    },

    selected() {
      this.setEmploymentInfo(this.employmentData);
      // this.verificationModal = false;
      if (this.type === "EMAIL") {
        this.showStep = "email";
      } else {
        this.showStep = "upload";
      }
    },
    handleClose() {
      this.$emit("close");
      this.$emit("approved");
    },
  },
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";

.container-body {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #f6f7ff;
  z-index: 5;
}

.container-body__surround {
  height: calc(100% - 1rem);
  overflow: auto;
}

.completed {
  background-color: #c70039;
}

.otp-button {
  position: absolute;
  right: 0;
  top: 0.5rem;
}

.spinner {
  position: absolute;
  right: 0.5rem;
  top: 2rem;
}

.line {
  background: linear-gradient(
    90deg,
    #c70039 3.72%,
    rgba(184, 184, 184, 0) 108.64%
  );
  border-radius: 100px;
}
</style>
