export const EMAIL_VALIDATION_STATUS = {
  pending: "PENDING",
  awaitingCompanyValidation: "VALIDATED_AWAITING_COMPANY_APPROVAL",
  active: "ACTIVE",
  expired: "EXPIRED",
};

export const EMPLOYMENT_DOCUMENT_STATUS = {
  pending: "PENDING",
  approved: "APPROVED",
  declined: "DECLINED",
  expired: "EXPIRED",
};
